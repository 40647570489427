import React from "react";
import { BsArrowRight } from "react-icons/bs";
import config from '../config'
import PlanetAstroidCard from "./cards/planetAstroidCard";
function Crewcard(props) {

            function getSubType(data){
                if(data){
                    const name = data.toLowerCase()
                    if(name.includes("rare")){
                        return "rare"
                        
                    }
                    if(name.includes("uncommon")){
                        return "uncommon"
                    }
                    if(name.includes("common")){
                        return "common"
                        
                    }


                }
                return null 
        
            }
    if (props?.product) {
        
        // const {cardType} = props
        // const cardType = "planet"
        const { product:{from, imageurl, price, coinname, nftname, putonsaletype, nftprice, nftid, owners, ipfs, image_url} , cardType} = props;



        return (
            (from === 'allgrid' || from === "limitgird") && 
            <div>
{ cardType === "planet" || cardType === "astroid" ?
                    // <div className="crewcard pb-3 pt-3 pos">
                    // <div className="text-center">
                    //     <img src={image_url ? image_url : ipfs} className="img-fluid crewimg" loading="lazy" onError={(e) => {
                    //         e.target.onerror = null; // Prevent infinite loop if fallback image also fails
                    //         e.target.src = config.NOIMAGE; // Fallback image URL
                    //     }} alt="crew" />
                    // </div>
                    // <p className="crewmate ms-2  ms-lg-2 ms-xl-2 ms-xxl-3 mb-0 mt-2">{nftname + " "} </p>
                    // <p className="crewmate ms-2 ms-lg-2 ms-xl-2 ms-xxl-3 fw-600">{nftprice ? nftprice : owners?.NFTPrice} {owners?.CoinName} </p>
                    // <p className="grayclr ms-2 ms-lg-2 ms-xl-2 ms-xxl-3 mb-0">{owners?.PutOnSaleType ? owners?.PutOnSaleType : putonsaletype} {" " + price ? price : owners?.NFTPrice + " "} {coinname ? coinname : owners?.CoinName}</p>
                    // {from === "stake" ? <p className="whtsclr f-16 mb-0 ar_right stakep">Stake</p> :
                    //     <BsArrowRight className="ar_right" />}
                    //  </div>
                    
                    <PlanetAstroidCard 
                    product = {props.product}
                    subType = {getSubType(props.product.nftname)}
                    />
                 :
cardType === "crew" ?

<div className="crewcard pb-3 pt-2 px-1 pos crewcardframe">
<div className={`text-center ${cardType === "crew" && "crewbg"}`}>
    <img src={image_url ? image_url : ipfs} className="img-fluid crewimg" loading="lazy" onError={(e) => {
        e.target.onerror = null; // Prevent infinite loop if fallback image also fails
        e.target.src = config.NOIMAGE; // Fallback image URL
    }} alt="crew" />
</div>
<p className={`crewmate ms-2  ms-lg-2 ms-xl-2 ms-xxl-3 mb-0 mt-2 ${cardType === "crew" && "crewmate_crew"}`}>{nftname + " "} </p>
<p className="crewmate ms-2 ms-lg-2 ms-xl-2 ms-xxl-3 fw-600 crewmate_num">{nftprice ? nftprice : owners?.NFTPrice} {owners?.CoinName} </p>
<p className="grayclr ms-2 ms-lg-2 ms-xl-2 ms-xxl-3 mb-0">{owners?.PutOnSaleType ? owners?.PutOnSaleType : putonsaletype} {" " + price ? price : owners?.NFTPrice + " "} {coinname ? coinname : owners?.CoinName}</p>
{from === "stake" ? <p className="whtsclr f-16 mb-0 ar_right stakep">Stake</p> :
    <BsArrowRight className="ar_right" />}
</div> 
: 
cardType === "specialcrew" ?

<div className="crewcard pb-3 pt-2 px-1 pos crewcardframe">
<div className={`text-center ${cardType === "specialcrew" && "specialcrewbg"}`}>
    <img src={image_url ? image_url : ipfs} className="img-fluid crewimg" loading="lazy" onError={(e) => {
        e.target.onerror = null; // Prevent infinite loop if fallback image also fails
        e.target.src = config.NOIMAGE; // Fallback image URL
    }} alt="crew" />
</div>
<p className={`crewmate ms-2  ms-lg-2 ms-xl-2 ms-xxl-3 mb-0 mt-2 ${cardType === "specialcrew" && "crewmate_specialcrew"}`}>{nftname + " "} </p>
<p className="crewmate ms-2 ms-lg-2 ms-xl-2 ms-xxl-3 fw-600 crewmate_num">{nftprice ? nftprice : owners?.NFTPrice} {owners?.CoinName} </p>
<p className="grayclr ms-2 ms-lg-2 ms-xl-2 ms-xxl-3 mb-0">{owners?.PutOnSaleType ? owners?.PutOnSaleType : putonsaletype} {" " + price ? price : owners?.NFTPrice + " "} {coinname ? coinname : owners?.CoinName}</p>
{from === "stake" ? <p className="whtsclr f-16 mb-0 ar_right stakep">Stake</p> :
    <BsArrowRight className="ar_right" />}
</div> 
: 

cardType === "ship" ?
<div className="crewcard pb-3 pt-2 px-1 pos crewcardframe">
<div className={`text-center ${cardType === "ship" && "shipbg"}`}>
    <img src={image_url ? image_url : ipfs} className="img-fluid crewimg" loading="lazy" onError={(e) => {
        e.target.onerror = null; // Prevent infinite loop if fallback image also fails
        e.target.src = config.NOIMAGE; // Fallback image URL
    }} alt="crew" />
</div>
<p className={`crewmate ms-2  ms-lg-2 ms-xl-2 ms-xxl-3 mb-0 mt-2 ${cardType === "ship" && "crewmate_ship"}`}>{nftname + " "} </p>
<p className="crewmate ms-2 ms-lg-2 ms-xl-2 ms-xxl-3 fw-600 crewmate_num">{nftprice ? nftprice : owners?.NFTPrice} {owners?.CoinName} </p>
<p className="grayclr ms-2 ms-lg-2 ms-xl-2 ms-xxl-3 mb-0">{owners?.PutOnSaleType ? owners?.PutOnSaleType : putonsaletype} {" " + price ? price : owners?.NFTPrice + " "} {coinname ? coinname : owners?.CoinName}</p>
{from === "stake" ? <p className="whtsclr f-16 mb-0 ar_right stakep">Stake</p> :
    <BsArrowRight className="ar_right" />}
</div> : <></> 


}


            </div>



        )
    }
}







export default Crewcard