import React, { useEffect, useState } from "react";
import { Container, Button } from 'react-bootstrap';
import crewimg from "../../Assets/crewimg.png";
import { BsArrowRight } from "react-icons/bs";
import config from '../../config'
const PlanetAstroidCard = ( props )=>{


    const { product:{from, imageurl, price, coinname, nftname, putonsaletype, nftprice, nftid, owners, ipfs, image_url} , 
    subType} = props



      
    return (
            <div className="crewcard pb-3 pt-2 px-1 pos crewcardframe">
                        <div className={`text-center ${(subType === "common" && "commonbg") || (subType === "uncommon" && "uncommonbg") || (subType === "rare" && "rarebg")}`}>
                            <img src={image_url ? image_url : ipfs} className="img-fluid crewimg" loading="lazy" onError={(e) => {
                                e.target.onerror = null; // Prevent infinite loop if fallback image also fails
                                e.target.src = config.NOIMAGE; // Fallback image URL
                            }} alt="crew" />
                        </div>
                        <p className={`crewmate ms-2  ms-lg-2 ms-xl-2 ms-xxl-3 mb-0 mt-2 ${(subType === "common" && "crewmate_common") || (subType === "uncommon" && "crewmate_uncommon") || (subType === "rare" && "crewmate_rare")}`}>{nftname + " "} </p>
                        <p className="crewmate ms-2 ms-lg-2 ms-xl-2 ms-xxl-3 fw-600 crewmate_num">{nftprice ? nftprice : owners?.NFTPrice} {owners?.CoinName} </p>
                        <p className="grayclr ms-2 ms-lg-2 ms-xl-2 ms-xxl-3 mb-0">{owners?.PutOnSaleType ? owners?.PutOnSaleType : putonsaletype} {" " + price ? price : owners?.NFTPrice + " "} {coinname ? coinname : owners?.CoinName}</p>

                         </div>

        ) 
}
export default PlanetAstroidCard